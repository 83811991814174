@import "https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap";
html {
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  background-color: var(--background-site);
  font-family: Inter, "system-ui", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  font-size: 62.5%;
  line-height: 1.15;
}

*, :before, :after {
  box-sizing: inherit;
}

body {
  background-color: var(--background-site);
  color: var(--text-high-contrast);
  font-size: 1.6rem;
  line-height: 1.65;
}

.theme-light {
  --brand-primary: #2f70c1;
  --brand-secondary: #7461c3;
  --brand-alternative: #137886;
  --background-site: #f9f9f9;
  --background-code: #f4f4f4;
  --text-body: #36313d;
  --text-comment: #635e69;
  --text-high-contrast: #313131;
  --text-medium-contrast: #635e69;
  --text-low-contrast: #746d76;
  --detail-high-contrast: silver;
  --detail-medium-contrast: #eaeaea;
  --detail-low-contrast: #f0f0f2;
  --admonition-note: #2e6dbc;
  --admonition-warning: #ffc409;
  --admonition-danger: #dc2626;
  --brand-primary-rgb-value: 47, 112, 193;
  --brand-secondary-rgb-value: 116, 97, 195;
  --brand-alternative-rgb-value: 19, 120, 134;
  --background-site-rgb-value: 249, 249, 249;
  --background-code-rgb-value: 244, 244, 244;
  --text-body-rgb-value: 54, 49, 61;
  --text-comment-rgb-value: 99, 94, 105;
  --text-high-contrast-rgb-value: 49, 49, 49;
  --text-medium-contrast-rgb-value: 99, 94, 105;
  --text-low-contrast-rgb-value: 116, 109, 118;
  --detail-high-contrast-rgb-value: 192, 192, 192;
  --detail-medium-contrast-rgb-value: 234, 234, 234;
  --detail-low-contrast-rgb-value: 240, 240, 242;
  --admonition-note-rgb-value: 46, 109, 188;
  --admonition-warning-rgb-value: 255, 196, 9;
  --admonition-danger-rgb-value: 220, 38, 38;
}

.theme-dark {
  --brand-primary: #8ab4f8;
  --brand-secondary: #c1a8e2;
  --brand-alternative: #88babf;
  --background-site: #000;
  --background-code: #0c0c0c;
  --text-body: #dedede;
  --text-comment: #aaa;
  --text-high-contrast: #e6e6e6;
  --text-medium-contrast: #cacaca;
  --text-low-contrast: #aaa;
  --detail-high-contrast: #656565;
  --detail-medium-contrast: #191919;
  --detail-low-contrast: #151515;
  --admonition-note: #8ab4f8;
  --admonition-warning: #fdba74;
  --admonition-danger: #dc2626;
  --brand-primary-rgb-value: 138, 180, 248;
  --brand-secondary-rgb-value: 193, 168, 226;
  --brand-alternative-rgb-value: 136, 186, 191;
  --background-site-rgb-value: 0, 0, 0;
  --background-code-rgb-value: 12, 12, 12;
  --text-body-rgb-value: 222, 222, 222;
  --text-comment-rgb-value: 170, 170, 170;
  --text-high-contrast-rgb-value: 230, 230, 230;
  --text-medium-contrast-rgb-value: 202, 202, 202;
  --text-low-contrast-rgb-value: 170, 170, 170;
  --detail-high-contrast-rgb-value: 101, 101, 101;
  --detail-medium-contrast-rgb-value: 25, 25, 25;
  --detail-low-contrast-rgb-value: 21, 21, 21;
  --admonition-note-rgb-value: 138, 180, 248;
  --admonition-warning-rgb-value: 253, 186, 116;
  --admonition-danger-rgb-value: 220, 38, 38;
}

.header {
  text-align: center;
  margin: 0;
  padding: 4rem 2rem 2rem;
  font-size: 2rem;
  font-weight: 700;
}

.footer {
  justify-content: center;
  padding: 2rem 2rem 4rem;
  display: flex;
}

.footer__link {
  text-align: center;
  color: var(--text-low-contrast);
  background-color: #0000;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 600;
  text-decoration: none;
  display: flex;
}

.footer__link__svg {
  fill: currentColor;
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  display: block;
}

.embla {
  --slide-height: 59rem;
  --slide-spacing: 1rem;
  --slide-size: 70%;
  max-width: 88rem;
  margin: auto;
}

.embla__viewport {
  overflow: hidden;
}

.embla__container {
  backface-visibility: hidden;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(var(--slide-spacing) * -1);
  display: flex;
}

.embla__slide {
  flex: 0 0 var(--slide-size);
  padding-left: var(--slide-spacing);
  min-width: 0;
}

.embla__slide__scientist {
  box-shadow: inset 0 0 0 .2rem var(--detail-medium-contrast);
  height: var(--slide-height);
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 1.8rem;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  font-weight: 600;
  display: flex;
}

.embla__controls {
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  gap: 1.2rem;
  margin-top: 1.8rem;
  display: grid;
}

.embla__buttons {
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  gap: .6rem;
  display: grid;
}

.embla__button {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), .5);
  appearance: none;
  touch-action: manipulation;
  cursor: pointer;
  box-shadow: inset 0 0 0 .2rem var(--detail-medium-contrast);
  z-index: 1;
  color: var(--text-body);
  background-color: #0000;
  border: 0;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 3.6rem;
  height: 3.6rem;
  margin: 0;
  padding: 0;
  text-decoration: none;
  display: flex;
}

.embla__button:disabled {
  color: var(--detail-high-contrast);
}

.embla__button__svg {
  width: 35%;
  height: 35%;
}

.embla__dots {
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  margin-right: -.6rem;
  display: flex;
}

.embla__dot {
  -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), .5);
  appearance: none;
  touch-action: manipulation;
  cursor: pointer;
  background-color: #0000;
  border: 0;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 2.6rem;
  height: 2.6rem;
  margin: 0;
  padding: 0;
  text-decoration: none;
  display: flex;
}

.embla__dot:after {
  box-shadow: inset 0 0 0 .2rem var(--detail-medium-contrast);
  content: "";
  border-radius: 50%;
  align-items: center;
  width: 1.4rem;
  height: 1.4rem;
  display: flex;
}

.embla__dot--selected:after {
  box-shadow: inset 0 0 0 .2rem var(--text-body);
}
/*# sourceMappingURL=index.2b85c18b.css.map */
